import * as cheerio from 'cheerio';
import { useValidationStore } from '@/stores/validationStore';
import { useSearch } from '@/composables/useSearch';
import { NameStatus, Validation } from '@/types/validation';


export const useValidation = () => {
    const validationStore = useValidationStore();
    const validationLoadingState = ref<"idle" | "pending" | "error">("idle");


    const validationIcons = {
        nameStatus: 'solar:tag-bold',
        biosecurityStatus: 'ph:bug-fill',
        complianceStatus: 'solar:check-circle-linear',
    }


    const { fetchBiosecurityData } = useSearch();

    const nameStatus: Ref<NameStatus> = ref({
        exact: [],
        inexact: [],
        invalid: []
    });

    const securityStatus = ref({
        host: [] as { species: string; source: string }[],
        pests: [] as { species: string; source: string }[],
    });

    const nuxtApp = useNuxtApp();

    // Create a cache object if it doesn't exist
    if (!nuxtApp.eppoCache) {
        nuxtApp.eppoCache = {};
    }

    // Generic function to fetch Eppo data based on type
    const findEppoData = async (type: 'hosts' | 'pests', eppocode: string) => {
        const cacheKey = `${type}:${eppocode}`;

        // Check if the data is already in the cache
        if (nuxtApp.eppoCache[cacheKey]) {
            return nuxtApp.eppoCache[cacheKey];
        }

        console.log(`/api/proxy/eppo/${type}`);
        const proxyResponse = await $fetch(`/api/proxy/eppo/${type}`, {
            method: 'POST',
            body: { eppocode },
            responseType: 'text',
        });
        const $ = cheerio.load(proxyResponse);
        const result = $('table#dttable tbody tr')
            .map((_, row) => ({
                name: $(row).find('td:nth-of-type(2) a').text().trim(),
                type: $(row).find('td:nth-of-type(3)').text().trim(),
                source: 'eppo' as const,
            }))
            .get();

        // Store the result in the cache
        nuxtApp.eppoCache[cacheKey] = result;

        return result;
    };

    const validateSpeciesNames = (namesToCheck: string[], gbif_names: string[], project_id: string) => {
        validationLoadingState.value = "pending";
        for (let name of namesToCheck.filter(Boolean)) {
            if (name !== 'undefined') {
                const lowercaseName = name.toLowerCase();
                const matchIndex = gbif_names.findIndex(gbif_name => 
                    gbif_name && gbif_name.toLowerCase() === lowercaseName
                );

                if (matchIndex !== -1) {
                    nameStatus.value.exact.push({ original: name, matched: gbif_names[matchIndex] });
                } else {
                    const partialMatchIndex = gbif_names.findIndex(gbif_name => 
                        gbif_name && gbif_name.toLowerCase().includes(lowercaseName)
                    );
                    if (partialMatchIndex !== -1) {
                        nameStatus.value.inexact.push({ original: name, matched: gbif_names[partialMatchIndex] });
                    } else {
                        nameStatus.value.invalid.push({ original: name, matched: null });
                    }
                }
            }
        }

        const validation: Validation = {
            name: 'nameStatus',
            result: nameStatus.value,
            show: nameStatus.value.invalid.length !== 0 || nameStatus.value.inexact.length !== 0
        };

        validationStore.addValidation(validation, project_id);

        validationLoadingState.value = "idle";
        return nameStatus.value;
    }

    const validateAndRefreshBiosecurity = async (
      speciesNames: string[],
      panel_id?: string,
      country?: string,
      libtype?: string,
      codelang: string = 'en'
    ) => {
      validationLoadingState.value = "pending";
      const { fetchOpenverse } = useSearch();
      const dockStore = useDockStore();
      const { panelState } = storeToRefs(dockStore);
      const client = useSupabaseClient();

      try {
        // Check cache for existing validations
        const { data: cachedValidations, error: cacheError } = await client
          .from('validations_biosecurity')
          .select('*')
          .in('species_name', unref(speciesNames))
          .gt('expires_at', new Date().toISOString());

        if (cacheError) {
          console.error("Error fetching cached validations:", cacheError);
        }

        const cachedSpecies = new Set(cachedValidations?.map(v => v.species_name) || []);
        const speciesToFetch = speciesNames.filter(name => !cachedSpecies.has(name));

        let validationResults: Record<string, any> = {};

        // Process cached results
        cachedValidations?.forEach(validation => {
          validationResults[validation.species_name] = validation.result;
        });

        if (speciesToFetch.length > 0) {
          const filters: string[] = [];
          if (country) filters.push(`country = ${country}`);
          if (libtype) filters.push(`libtype = ${libtype}`);

          const searchResults = await fetchBiosecurityData(speciesToFetch, filters);
          console.log('Search results:', searchResults);

          const upsertPromises = speciesToFetch.map(async (name, index) => {
            const eppoHits = searchResults.eppo?.[index]?.hits || [];
            const padilData = searchResults.padil?.[index]?.data || [];

            let threatData = {
              isBiosecurityThreat: false,
              hosts: [] as { name: string; type: string; source: 'eppo' | 'padil' }[],
              pests: [] as { name: string; type: string; source: 'eppo' | 'padil' }[],
            };

            if (eppoHits.length > 0 || padilData.length > 0) {
              // Process EPPO data
              await Promise.all(
                eppoHits.map(async (species: any) => {
                  if (species?.libtype?.includes("animal") && species?.codelang?.includes(codelang)) {
                    const hosts = await findEppoData("hosts", species.eppocode);
                    if (Array.isArray(hosts)) {
                      threatData.hosts.push(...hosts.map(host => ({ ...host, source: 'eppo' as const })));
                    }
                  }
                  if (species?.libtype?.includes("plant") && species?.codelang?.includes(codelang)) {
                    const pests = await findEppoData("pests", species.eppocode);
                    if (Array.isArray(pests)) {
                      threatData.pests.push(...pests.map(pest => ({ ...pest, source: 'eppo' as const })));
                    }
                  }
                })
              );

              // Process PADIL data
              padilData.forEach((pest: any) => {
                if (pest?.scientificName && pest?.taxonomyLvl1) {
                  threatData.hosts.push({
                    name: pest.scientificName,
                    type: pest.taxonomyLvl1,
                    source: 'padil',
                  });
                }
              });

              threatData.isBiosecurityThreat = threatData.hosts.length > 0 || threatData.pests.length > 0;
            }

            validationResults[name] = threatData;

            // Modify the upsert operation
            return client
              .from('validations_biosecurity')
              .upsert(
                {
                  species_name: name,
                  api: 'eppo_padil',
                  result: threatData,
                  expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // Cache for 7 days
                },
                {
                  onConflict: 'species_name,api',
                  ignoreDuplicates: false
                }
              );
          });

          // Wait for all upsert operations to complete
          await Promise.all(upsertPromises);
        }

        // Fetch images for species with biosecurity threats
        const threatEntries = Object.entries(validationResults)
          .filter(([_, data]) => data.isBiosecurityThreat);

        const mappedEntries = await Promise.all(threatEntries.map(async ([species, data]) => {
          const { openverseResults } = await fetchOpenverse([species]);
          const image = openverseResults.value?.[0]?.url || null;

          return [species, {
            ...data,
            hosts: data.hosts ?? [],
            pests: data.pests ?? [],
            image,
            scheduleChanged: false
          }];
        }));

        validationResults = Object.fromEntries(mappedEntries);

        validationLoadingState.value = "idle";
        console.log('validationResults', validationResults)
        return {
          result: validationResults,
          name: 'biosecurityStatus',
          show: Object.values(validationResults).some(result => result.isBiosecurityThreat)
        };
      } catch (error) {
        console.error("Error validating species against biosecurity data:", error);
        validationLoadingState.value = "error";
        return {
          result: {},
          name: 'biosecurityStatus',
          show: false
        };
      }
    };

    const getSpeciesNames = (
        data?: string[],
        panelState?: any,
        panel_id?: string,
        availablePanels?: any[]
    ): string[] => {
        if (data) {
            return data
        }
        let output = panelState?.[panel_id]?.output || panelState?.[panel_id]?.data?.output
        let rows = output?.tables?.rows || []
        let metadata = toRaw(panelState?.[panel_id]?.output?.metadata || panelState?.[panel_id]?.data?.output?.metadata || availablePanels?.find(p => p.panel_id === panel_id)?.metadata)
        let mapped = rows.map(r => {
            return r[metadata?.columnToCanonical?.botanicalname]
        })
        return mapped || []
    }

    const fetchTnrs = async (invalidNames: string[]) => {
      const { data: tnrsData, error: tnrsError } = await useAsyncData(
        `tnrs-${invalidNames.join(',')}`,
        () => $fetch('/api/bio/tnrs', {
          method: 'POST',
          body: { searchQuery: invalidNames.join(',') }
        }),
        {
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          }
        }
      )

      console.log('tnrsData', tnrsData.value)
      
      if (tnrsError.value) {
        console.error('Error fetching TNRS data:', tnrsError.value)
      }

      return { result: toRaw(tnrsData.value), error: toRaw(tnrsError.value) }
    }

    

    return {
        validateSpeciesNames,
        nameStatus,
        validationLoadingState,
        validateAndRefreshBiosecurity,
        findEppoData,
        getSpeciesNames,
        validationIcons,
        fetchTnrs
    }
}
